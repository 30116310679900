export default function Paper(theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },

      variants: [
        {
          props: { variant: 'outlined' },
          style: { borderColor: theme.palette.grey['500_12'] },
        },
        {
          props: { variant: 'inset' },
          style: {
            boxShadow: 'inset 0px 0px 24px -4px rgba(0,0,0,0.2)',
            backgroundColor: theme.palette.background.neutral,
          },
        },
      ],

      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  };
}
