import i18next from 'i18next';
import { applicationsGraphMockDetails, clusterId, notificationsListMock } from 'src/contexts/constants/tourMocks';
import { TourToolTipContainer } from '../TourToolTipContainer';
import { TOUR_SETTINGS } from '../constants';
import { calculatePosition } from './utils';

export const setApplicationsSteps = (step, navigate, setStep, setTourState, submitCompletedUITours, tourState) => {
  const navigateToNotificationsPanel = () => {
    navigate(
      `clusters/${clusterId}/app/${applicationsGraphMockDetails.data.id.replace('workload:', '')}/detail/summary/notifications?id=${
        notificationsListMock.data[0].id
      }`,
      { replace: true }
    );
  };
  switch (step) {
    case 0:
      navigate(`clusters/${clusterId}/detail/apps`, { replace: true });
      setTimeout(() => {
        setStep(step);
      }, 400);
      break;
    case 1:
    case 2:
      navigate(`clusters/${clusterId}/detail/apps-graph`, { replace: true });
      setTourState({ isShowAppGraph: true, isShowAppGraphIsolate: false });
      setTimeout(() => {
        setStep(step);
      }, 200);
      break;
    case 3:
      setTourState({ isShowAppGraphIsolate: true, isShowAppGraphPanel: false });
      if (tourState.isShowAppGraphPanel) {
        setTimeout(() => {
          setStep(step);
        }, 1000);
      } else {
        setTimeout(() => {
          setStep(step);
        }, 800);
      }
      break;
    case 4:
    case 5:
      navigate(`clusters/${clusterId}/detail/apps-graph`, { replace: true });
      setTourState({ isShowAppGraphPanel: true, isShowAppGraphIsolate: false });
      setTimeout(() => {
        setStep(step);
      }, 800);
      break;
    case 6:
    case 8:
      navigate(`clusters/${clusterId}/app/${applicationsGraphMockDetails.data.id.replace('workload:', '')}/detail/summary`, {
        replace: true,
      });
      setTimeout(() => {
        setStep(step);
      }, 300);
      break;
    case 9:
      navigate(`clusters/${clusterId}/app/${applicationsGraphMockDetails.data.id.replace('workload:', '')}/detail/summary/notifications`, {
        replace: true,
      });
      setTimeout(() => {
        setStep(step);
      }, 200);
      break;
    case 10:
      navigateToNotificationsPanel();
      setTimeout(() => {
        setStep(step);
      }, 200);
      break;
    case 11:
      navigateToNotificationsPanel(); // need to have this twice as there could possibly be a race condition with step 12
      setTimeout(() => {
        navigateToNotificationsPanel(); // need to have this twice as there could possibly be a race condition with step 12
      }, 10);
      setTimeout(() => {
        setStep(step);
      }, 800);
      break;
    case 12:
      navigate(
        `clusters/${clusterId}/app/${applicationsGraphMockDetails.data.id.replace(
          'workload:',
          ''
        )}/detail/summary/errors?chart=grpc_error_rate`,
        { replace: true }
      );
      submitCompletedUITours(TOUR_SETTINGS.APPLICATIONS_FINISHED);
      setTimeout(() => {
        setStep(step);
      }, 400);
      break;

    default:
      setStep(step);
  }
};

export const applicationsSteps = [
  {
    selector: '[data-tour="cluster-apps-list"]',
    position: (props) => [props.left, props.bottom - props.height / 2 - 20],
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appList.title')}>
        {i18next.t('tour.applicationsScenario.appList.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="apps-graph-page-container"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appGraph.title')}>
        {i18next.t('tour.applicationsScenario.appGraph.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="apps-graph-page"]',
    position: calculatePosition,
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appGraphPage.title')}>
        {i18next.t('tour.applicationsScenario.appGraphPage.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="apps-graph-isolate"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appGraphIsolate.title')}>
        {i18next.t('tour.applicationsScenario.appGraphIsolate.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="apps-graph-page"]',
    position: calculatePosition,
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appGraphPageDetails.title')}>
        {i18next.t('tour.applicationsScenario.appGraphPageDetails.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="applications-details-link"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appDetails.title')}>
        {i18next.t('tour.applicationsScenario.appDetails.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="applications-details"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appSummary.title')}>
        {i18next.t('tour.applicationsScenario.appSummary.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="applications-details-usage"]',
    position: (props) => [20, props.windowHeight - props.height - 20],
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appSummaryPage.title')}>
        {i18next.t('tour.applicationsScenario.appSummaryPage.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="applications-details-insights"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appInsights.title')}>
        {i18next.t('tour.applicationsScenario.appInsights.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="applications-notifications-page"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appNotifications.title')}>
        {i18next.t('tour.applicationsScenario.appNotifications.content')}
      </TourToolTipContainer>
    ),
  },

  {
    selector: '[data-tour="applications-alert-details"]',
    position: (props) => [props.windowWidth / 2 - props.width - 20, 20],
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appMetricsAlert.title')}>
        {i18next.t('tour.applicationsScenario.appMetricsAlert.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="applications-alert-details-source"]',
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appMetricsAlertSource.title')}>
        {i18next.t('tour.applicationsScenario.appMetricsAlertSource.content')}
      </TourToolTipContainer>
    ),
  },
  {
    selector: '[data-tour="application-throughput-metrics"]',
    position: (props) => calculatePosition(props, 'bottom'),
    content: (
      <TourToolTipContainer title={i18next.t('tour.applicationsScenario.appThroughputMetrics.title')}>
        {i18next.t('tour.applicationsScenario.appThroughputMetrics.content')}
      </TourToolTipContainer>
    ),
  },
];
