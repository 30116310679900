import { useAuth0 } from '@auth0/auth0-react';
import { Scrollbar } from '@diagrid/cloud-ui-shared';
import { useLocalStorage } from '@diagrid/cloud-ui-shared/hooks';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Box, Button, styled, TextField, Typography, useTheme } from '@mui/material';
import Fuse from 'fuse.js';
import { forEach } from 'lodash';
import { useMemo, useState } from 'react';
import { useLocales } from 'src/hooks/useLocales';
import { useFindQuery } from 'src/redux/store';
import { QuickFindItem, QuickFindItemType } from './QuickFindItem';

const MAX_RESULTS_CLUSTERS = 100;
const MAX_RESULTS_APPS = 100;
const MAX_RECENT_ITEMS = 5;

export function QuickFindDrawer({ onClose }: { onClose: () => void }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<{ clusters: boolean; apps: boolean; components: boolean }>({
    clusters: true,
    apps: true,
    components: true,
  });
  const [recentItems, setRecentItems] = useLocalStorage<{ [key: string]: QuickFindItemType[] }>('quickFindRecentResources', {});
  const { user } = useAuth0();
  const [currentOrgId] = useLocalStorage(`${user?.sub}/currentOrg`, user?.defaultOrganization);

  const { data: filtersData } = useFindQuery(
    {
      type: 'diagridNotificationsFilters',
      data: {},
      normalization: { skip: true },
    },
    { refetchOnMountOrArgChange: true }
  );

  const data = useMemo(() => {
    const clusterItems = [];
    const appItems = [];
    const componentItems = [];
    if (filtersData?.data?.clusters) {
      const { clusters } = filtersData.data;
      forEach(clusters, (cluster) => {
        clusterItems.push({
          id: cluster.id,
          name: cluster.name,
          type: 'cluster',
        });
        forEach(cluster.workloads, (workload) => {
          appItems.push({
            ...workload,
            name: workload.id.split(':')[1],
            clusterName: cluster.name,
            type: 'app',
          });
        });
        forEach(cluster.components, (component) => {
          componentItems.push({
            ...component,
            name: component.id.split(':')[1],
            clusterName: cluster.name,
            type: 'component',
          });
        });
      });
    }

    return {
      clusterItems,
      appItems,
      componentItems,
    };
  }, [filtersData]);

  const { filteredClusters, filteredApps, filteredClustersCount, filteredAppsCount, filteredComponents, filteredComponentsCount } =
    useMemo(() => {
      const SCORE_THRESHOLD = 0.05;
      if (searchTerm) {
        const { clusterItems, appItems, componentItems } = data;
        const fuseClusters = new Fuse(clusterItems, {
          keys: ['id', 'name'],
          includeScore: true,
        });
        const fuseApps = new Fuse(appItems, {
          keys: ['clusterId', 'id', 'name', 'namespace'],
          includeScore: true,
        });
        const fuseComponents = new Fuse(componentItems, {
          keys: ['clusterId', 'id', 'name', 'namespace'],
          includeScore: true,
        });
        const filteredClusters = filters.clusters ? fuseClusters.search(searchTerm).filter((s) => s.score <= SCORE_THRESHOLD) : [];
        const filteredApps = filters.apps ? fuseApps.search(searchTerm).filter((s) => s.score <= SCORE_THRESHOLD) : [];
        const filteredComponents = filters.components ? fuseComponents.search(searchTerm).filter((s) => s.score <= SCORE_THRESHOLD) : [];
        return {
          filteredClusters: filteredClusters.slice(0, MAX_RESULTS_CLUSTERS),
          filteredClustersCount: filteredClusters.length - MAX_RESULTS_CLUSTERS,
          filteredApps: filteredApps.slice(0, MAX_RESULTS_APPS),
          filteredAppsCount: filteredApps.length - MAX_RESULTS_APPS,
          filteredComponents: filteredComponents.slice(0, MAX_RESULTS_APPS),
          filteredComponentsCount: filteredComponents.length - MAX_RESULTS_APPS,
        };
      }
      return {
        filteredClusters: [],
        filteredClustersCount: 0,
        filteredApps: [],
        filteredAppsCount: 0,
        filteredComponents: [],
        filteredComponentsCount: 0,
      };
    }, [searchTerm, data, filters]);

  const saveToLocalStorage = (item) => {
    const currentRecentItems = recentItems[currentOrgId] ?? [];
    const hasSome = currentRecentItems?.some(({ id }) => id === item.id);

    if (hasSome) {
      setRecentItems({ ...recentItems, [currentOrgId]: [item, ...currentRecentItems.filter(({ id }) => id !== item.id)] });
    } else {
      setRecentItems({ ...recentItems, [currentOrgId]: [item, ...currentRecentItems.slice(0, MAX_RECENT_ITEMS - 1)] });
    }

    onClose();
  };

  const recentItemsList = useMemo(() => {
    // Filter out recent items that no longer exist in data
    const { clusterItems, appItems, componentItems } = data;
    const validRecentItems = (recentItems[currentOrgId] ?? []).filter((recentItem) => {
      if (recentItem.type === 'app') {
        // For apps, check if both cluster and app still exist
        return appItems.some((app) => app.id === recentItem.id && app.clusterId === recentItem.clusterId);
      }
      if (recentItem.type === 'component') {
        // For clusters, just check if cluster exists
        return componentItems.some((component) => component.id === recentItem.id);
      }
      if (recentItem.type === 'cluster') {
        // For clusters, just check if cluster exists
        return clusterItems.some((cluster) => cluster.id === recentItem.id);
      }
      return [];
    });

    return validRecentItems;
  }, [recentItems, currentOrgId, data]);

  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '1rem',
        left: '1rem',
        width: '28rem',
        maxHeight: '94vh',
        zIndex: 1000,
        background: isDarkMode ? theme.palette.grey[900] : theme.palette.grey[100],
        boxShadow: isDarkMode ? theme.shadows[10] : theme.shadows[20],
        p: 2,
        pr: 1,
        border: `1px solid ${isDarkMode ? theme.palette.grey[700] : theme.palette.grey[400]}`,
        borderRadius: 1,
      }}
    >
      <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
        {translate('components.quickFind.title')}
      </Typography>
      <TextField
        size="small"
        fullWidth
        sx={{ mt: 2, mb: 1, pr: 2 }}
        label={translate('generalLabels.search')}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        autoFocus
      />
      {searchTerm && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'space-between', mr: 2, mb: 2 }}>
          <Box>{translate('generalLabels.show')}:</Box>
          <Button
            size="small"
            variant="outlined"
            startIcon={filters.apps ? <CheckBox /> : <CheckBoxOutlineBlank />}
            onClick={() => setFilters({ ...filters, apps: !filters.apps })}
          >
            {translate('generalLabels.apps')}
          </Button>
          <Button
            size="small"
            variant="outlined"
            startIcon={filters.clusters ? <CheckBox /> : <CheckBoxOutlineBlank />}
            onClick={() => setFilters({ ...filters, clusters: !filters.clusters })}
          >
            {translate('generalLabels.clusters')}
          </Button>
          <Button
            size="small"
            variant="outlined"
            startIcon={filters.components ? <CheckBox /> : <CheckBoxOutlineBlank />}
            onClick={() => setFilters({ ...filters, components: !filters.components })}
          >
            {translate('generalLabels.components')}
          </Button>
        </Box>
      )}
      <Scrollbar sx={{ maxHeight: 'calc(94vh - 9rem)' }} tabIndex={-1}>
        {filteredClusters.length === 0 && filteredApps.length === 0 && filteredComponents.length === 0 && searchTerm && (
          <Typography sx={{ fontStyle: 'italic', opacity: 0.5, my: 2, color: theme.palette.text.primary }}>
            {translate('components.quickFind.noResults')}
          </Typography>
        )}
        {filteredClusters.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <StyledQuickFindTittle variant="body2">{translate('generalLabels.clusters')}</StyledQuickFindTittle>

            {filteredClusters.map(({ item }) => (
              <QuickFindItem item={item} key={item.id} onClick={() => saveToLocalStorage(item)} />
            ))}
            {filteredClusters.length === MAX_RESULTS_CLUSTERS && (
              <Typography sx={{ fontStyle: 'italic', opacity: 0.5, my: 2, color: theme.palette.text.primary }}>
                {translate('components.quickFind.moreResults', { count: filteredClustersCount })}
              </Typography>
            )}
          </Box>
        )}
        {filteredApps.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <StyledQuickFindTittle variant="body2">{translate('generalLabels.apps')}</StyledQuickFindTittle>
            {filteredApps.map(({ item }, index) => (
              <QuickFindItem item={item} key={`${item.id}-${item?.clusterId ?? index}`} onClick={() => saveToLocalStorage(item)} />
            ))}
            {filteredApps.length === MAX_RESULTS_APPS && (
              <Typography sx={{ fontStyle: 'italic', opacity: 0.5, my: 2 }}>
                {translate('components.quickFind.moreResults', { count: filteredAppsCount })}
              </Typography>
            )}
          </Box>
        )}
        {filteredComponents.length > 0 && (
          <Box>
            <StyledQuickFindTittle variant="body2">{translate('generalLabels.components')}</StyledQuickFindTittle>
            {filteredComponents.map(({ item }, index) => (
              <QuickFindItem item={item} key={`${item.id}-${item?.clusterId ?? index}`} onClick={() => saveToLocalStorage(item)} />
            ))}
            {filteredComponents.length === MAX_RESULTS_APPS && (
              <Typography sx={{ fontStyle: 'italic', opacity: 0.5, my: 2 }}>
                {translate('components.quickFind.moreResults', { count: filteredComponentsCount })}
              </Typography>
            )}
          </Box>
        )}
        {recentItemsList.length > 0 && !searchTerm && (
          <Box sx={{ mt: 2 }}>
            <StyledQuickFindTittle variant="body2">{translate('generalLabels.recent')}</StyledQuickFindTittle>
            {recentItemsList.map((item, index) => (
              <QuickFindItem item={item} key={`${item.id}-${item?.clusterId ?? index}`} onClick={onClose} />
            ))}
          </Box>
        )}
      </Scrollbar>
    </Box>
  );
}

const StyledQuickFindTittle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  mt: 3,
  fontSize: '0.875rem',
  opacity: 0.5,
  color: theme.palette.text.primary,
}));
